<template>
    <div>
        <a-sidebar></a-sidebar>
        <div class="main-content" id="panel">
            <a-mobile-sidebar></a-mobile-sidebar>
            <a-header title="Customer Orders" class="bg-blue text-white">
                <div class="col-md-12">
                    <stats-card card-title="Orders" card-sub-title="Manage Orders">
                    </stats-card>
                </div>
            </a-header>
            <a-content>
                <div class="col-md-12" v-if="!showRecordPage">
                    <table-card card-title="All Orders">
                        <table class="table align-items-center table-flush" v-if="records">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col">Order ID</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Status</th>
                                <th scope="col">By</th>
                                <th scope="col"><i class="ni ni-ui-04"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="record in records" :key="record.order_id">
                                <td><small>{{ record.order_code }}</small></td>
                                <td>{{ record.currency }} {{ record.amount_due.toLocaleString() }}</td>
                                <td> 
                                    <div class="badge" :class="record.payment_status == 'paid' ?'badge-success' : 'badge-primary'">{{ record.payment_status }}</div>
                                    <div class="badge badge-success" v-if="record.delivery && record.delivery.status == 'delivered'"> &nbsp; delivered</div>
                                </td>
                                <td> {{ record.customer.username }}</td>
                                <td>
                                    <button @click="openShowRecord(record)" class="btn btn-dark btn-sm"><i class="fas fa-eye"></i></button>
                                    <button v-if="record.payment_status == 'unpaid'" @click="deleteRecord(record)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else class="alert alert-secondary">
                            No Records Yet!
                        </div>
                    </table-card>
                </div>
                <div class="col-md-8" v-if="showRecordPage">
                    <show-card :cardTitle="showRecordData.order_code" :cardSubTitle="'Order ID'" >  
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <th><i class="fas fa-store"></i> &nbsp; &nbsp; Products Ordered</th>
                                        <td>
                                            <span v-for="product in showRecordData.products" :key="product.id">{{product.name}} <br></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-hand-holding-usd"></i> &nbsp; &nbsp; Amount Due</th>
                                        <td>{{showRecordData.currency}} {{showRecordData.amount_due.toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-hand-holding-usd"></i> &nbsp; &nbsp; Delivery Fee</th>
                                        <td>{{showRecordData.currency}} {{showRecordData.delivery_fee.toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-hand-holding-usd"></i> &nbsp; &nbsp; Amount Paid</th>
                                        <td>{{showRecordData.currency}} {{showRecordData.amount_paid.toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-receipt"></i> &nbsp; &nbsp; Payment Status</th>
                                        <td><div class="badge" :class="showRecordData.payment_status == 'paid' ?'badge-success' : 'badge-primary'">{{ showRecordData.payment_status }}</div></td>
                                    </tr>
                                </tbody>
                                <tbody v-if="showRecordData.delivery">
                                    <tr>
                                        <th><i class="fas fa-weight"></i> &nbsp; &nbsp; Tracking Code</th>
                                        <td>{{showRecordData.delivery.tracking_code}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-map-marker"></i> &nbsp; &nbsp; Current Location</th>
                                        <td>{{showRecordData.delivery.info}} </td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-map-marker"></i> &nbsp; &nbsp; Recipient Info</th>
                                        <td>{{showRecordData.delivery.recipient_name}} <br>
                                            {{showRecordData.delivery.recipient_state_residence}} <br>
                                            {{showRecordData.delivery.recipient_phone}} <br>
                                            {{showRecordData.delivery.recipient_address}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-toggle-on"></i> &nbsp; &nbsp; Delivery Status</th>
                                        <td v-if="showRecordData.delivery.status == 'pending'" class="text-danger">{{showRecordData.delivery.status}}</td>
                                        <td v-else-if="showRecordData.delivery.status == 'intransit'" class="text-primary">{{showRecordData.delivery.status}}</td>
                                        <td v-else class="text-success">{{showRecordData.delivery.status}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-clock"></i> &nbsp; &nbsp; Created On</th>
                                        <td>{{showRecordData.created_at}}</td>
                                    </tr>
                                    <tr>
                                        <th><i class="fas fa-clock"></i> &nbsp; &nbsp; Updated</th>
                                        <td>{{showRecordData.updated_at}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                         ..
                        <div class="text-right">
                            <button type="button" @click="closeShowRecord" class="btn btn-light btn-sm"><i class="fas fa-window-close"></i> close</button>
                        </div>
                    </show-card>
                </div>
                <div class="col-md-4" v-if="showRecordPage">
                    <form-card card-title="Delivery Info" card-sub-title="Update Delivery Info">
                        <form v-on:submit.prevent>
                            <div class="form-group">
                                <div>
                                    <label for="">Current Location</label>
                                    <input class="form-control" type="text" v-model="form.info" :disabled="!form.tracking_code">
                                </div>
                            </div>
                            <div class="form-group">
                                <div>
                                    <label for="">Select Status</label>
                                    <select class="form-control" v-model="form.delivery_status" :disabled="!form.tracking_code || (showRecordData.delivery && showRecordData.delivery.status == 'delivered')">
                                        <option v-for="option in ['pending','intransit','delivered']" :value="option" :key="option">{{option}}</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary my-4" @click="updateDelivery" v-if="form.tracking_code">Update</button>
                                <button type="button" @click="closeShowRecord" class="btn btn-light"><i class="fas fa-window-close"></i> close</button>
                            </div>
                        </form>
                    </form-card>
                </div>
            </a-content>
            <a-footer></a-footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
import AdminContentVue from '../../components/Admin/AdminContent.vue'
import AdminFooterVue from '../../components/Admin/AdminFooter.vue'
import AdminHeaderVue from '../../components/Admin/AdminHeader.vue'
import NoButtonWhiteCardVue from '../../components/Cards/NoButtonWhiteCard.vue'
import StatsCardVue from '../../components/Cards/StatsCard.vue'
import TableOneButtonCardVue from '../../components/Cards/TableOneButtonCard.vue'
import AdminMobileNavVue from '../../components/Nav/AdminMobileNav.vue'
import AdminSideBarVue from '../../components/Nav/AdminSideBar.vue'
import TwoButtonColorCardVue from '../../components/Cards/TwoButtonColorCard.vue';
export default {
    name: 'Orders',
    components: {
        'a-header': AdminHeaderVue,
        'a-mobile-sidebar': AdminMobileNavVue,
        'a-sidebar': AdminSideBarVue,
        'a-content': AdminContentVue,
        'a-footer': AdminFooterVue,
        'stats-card': StatsCardVue,
        'table-card': TableOneButtonCardVue,
        'form-card': NoButtonWhiteCardVue, 
        'show-card': TwoButtonColorCardVue,
    },
    computed : {
        ...mapState({
            authToken : state => state.authToken
        })
    },
    mounted(){
        if (this.authToken) {
            this.getRecords()
        }
        else{
            this.$router.push('/login')
        }
    },
    data() {
        return{
            records: [],
            showRecordPage: false,
            form:{}
        }
    },
    methods:{
        updateDelivery(){
            let loader = this.$loading.show()
            axios.put(process.env.VUE_APP_BASEURL+'/shop/orders/'+this.recordId+'?delivery='+1, this.form,
            { 
                 headers: { 'Authorization': 'Bearer '+this.authToken }
            })
            .then(() => {
                //Perform Success Action
                this.$moshaToast('Update Successful!',{
                    type: 'success'
                });
                this.getRecords();
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
            .finally(() => {
                //Perform other dependent actions
                loader.hide()
            });
        },

        getRecords(){
            axios.get(process.env.VUE_APP_BASEURL+'/shop/orders?admin='+1,
            {
                headers: { 'Authorization': 'Bearer '+this.authToken }
            })
            .then(response => {
                this.records = response.data.data
                this.showRecordPage = false
                this.form = {}
            })
            .catch(error => {
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
                if (error.response.status === 401) {
                    localStorage.removeItem('mytoken');
                    this.$router.push('/login');
                }
            })
        },
        deleteRecord(record){
            if (confirm('Do you want to delete this Unpaid Order?')) { 
                let loader = this.$loading.show()
                axios.delete(process.env.VUE_APP_BASEURL+'/shop/orders/'+record.order_id,
                { 
                    headers: { 'Authorization': 'Bearer '+this.authToken }
                })
                .then(() => {
                    this.$moshaToast('Deleted Successful!',{
                        type: 'success'
                    });
                    this.getRecords();
                })
                .catch((error) => {
                    this.$moshaToast(error.response.data.message,{
                        type: 'danger'
                    });
                })
                .finally(() => {
                    loader.hide()
                });
            }
        },
        closeUpdateForm(){
            this.showUpdateForm = false,
            this.form = {}
        },
        openShowRecord(record){
            this.showRecordPage = true,
            this.showRecordData = record
            this.recordId = record.order_id,
            this.form.tracking_code = record.delivery ? record.delivery.tracking_code : null,
            this.form.info = record.delivery ? record.delivery.info : null,
            this.form.delivery_status = record.delivery ? record.delivery.status : null
        },
        closeShowRecord(){
            this.showRecordPage = false
        }

    },
}
</script>